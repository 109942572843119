import Penguin1 from "../images/reference/1.png"
import Penguin2 from "../images/reference/2.png"
import Penguin3 from "../images/reference/3.png"
import Penguin4 from "../images/reference/verze-1-6_4.png"
import Penguin5 from "../images/reference/verze-1-6_5.png"
import Penguin6 from "../images/reference/verze-1-6_6.png"
import VerticalPenguin1 from "../images/reference/vertical-1.png"
import VerticalPenguin2 from "../images/reference/vertical-2.png"
import VerticalPenguin3 from "../images/reference/vertical-3.png"
import VerticalPenguin4 from "../images/reference/vertical-verze-1-6_4.png"
import VerticalPenguin5 from "../images/reference/vertical-verze-1-6_5.png"
import VerticalPenguin6 from "../images/reference/vertical-verze-1-6_6.png"

export const TIMELINE_GLOBALIT = [
  {
    img: Penguin1,
    verticalImg: VerticalPenguin1,
    text: "Výzkum a analýza",
  },
  {
    img: Penguin2,
    verticalImg: VerticalPenguin2,
    text: "Technologie a návrh aplikace",
  },
  {
    img: Penguin3,
    verticalImg: VerticalPenguin3,
    text: "Struktura dat",
  },
  {
    img: Penguin4,
    verticalImg: VerticalPenguin4,
    text: "Reporty a filtry",
  },
  {
    img: Penguin5,
    verticalImg: VerticalPenguin5,
    text: "Automatizace na míru",
  },
  {
    img: Penguin6,
    verticalImg: VerticalPenguin6,
    text: "Uživatelská práva",
  },
]
