import React from "react"
import { Col, Container, Row } from "reactstrap"
import styled from "styled-components"

type Props = {
  data: Array<{ img: string; text: string; verticalImg?: string }>
}

const TextWrapper = styled.div`
  width: 250px;
`

const PenguinsTimeline: React.FC<Props> = ({ data }) => {
  return (
    <>
      <Container fluid className="d-lg-flex justify-content-center">
        {data.map((item, index) => {
          return (
            <>
              <div
                key={index}
                className="d-none d-lg-inline-block justify-content-center"
              >
                <img
                  src={item.img}
                  alt=""
                  width="250"
                  height="246"
                  loading="lazy"
                />
                <TextWrapper className="text-center d-flex justify-content-center">
                  <strong>{item.text}</strong>
                </TextWrapper>
              </div>

              <Row
                key={index}
                className="d-lg-none justify-content-center lg-justify-content-between"
              >
                <Col xs={4} md={3} className="d-flex justify-content-center">
                  <img
                    src={item.verticalImg}
                    alt=""
                    width="108"
                    height="134"
                    loading="lazy"
                  />
                </Col>
                <Col xs={7} md={3} className="my-auto d-flex">
                  <strong>{item.text}</strong>
                </Col>
              </Row>
            </>
          )
        })}
      </Container>
    </>
  )
}

export default PenguinsTimeline
