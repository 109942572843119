import React from "react"
import { Helmet } from "react-helmet"
import { ContactForm, Layout } from "../../../components"
import { Content, H2, Medailon, PaddingWrapper } from "@igloonet-web/shared-ui"
import ReferenceHeader from "../../../components/reference-detail/reference-header"
import ReferenceImageText from "../../../components/reference-detail/reference-image-text"
import Monitor from "../../../images/reference/global-it/monitor.png"
import MobilniZobrazeni from "../../../images/reference/global-it/mobilni-zobrazeni.png"
import Logo from "../../../images/reference/global-it/logo.jpg"
import OgImage from "../../../images/reference/global-it/og-image.png"
import { Link } from "gatsby"
import { Container } from "reactstrap"
import Sobol from "../../../images/team/mini/sobol.jpg"
import PenguinsTimeline from "../../../components/reference-detail/penguins-timeline"
import { TIMELINE_GLOBALIT } from "../../../model/reference-timeline-globalIT"

const GlobalIT: React.FC = () => {
  return (
    <Layout>
      <Helmet>
        <title>Automatizace HR procesů na míru | igloonet.cz</title>
        <meta
          name="description"
          content="Vytvořili jsme EMS (emloyee management system) aplikaci, která v Global IT Center zautomatizovala každodenní HR rutinu i nejčastější firemní procesy. Podívejte se, co všechno umí a proč ji chcete mít taky"
        />
        <meta
          property="og:title"
          content="Automatizace HR procesů na míru | igloonet.cz"
        />
        <meta property="og:image" content={OgImage} />
      </Helmet>
      <ReferenceHeader
        companyName="Global IT Center"
        heading="Automatizace HR procesů na míru"
        date="únor 2020 – únor 2021"
      />
      <ReferenceImageText
        right
        img={Monitor}
        eager
        alt=""
        logo={Logo}
        logoAlt="logo Global IT Center"
        link="//gitc.heidelbergcement.com/cs/"
      >
        <p>
          Vytvořili jsme EMS (emloyee management system) aplikaci, která
          v&nbsp;Global IT Center zautomatizovala každodenní HR rutinu
          i&nbsp;nejčastější firemní procesy. Podívejte se, co všechno umí
          a&nbsp;proč ji chcete mít taky 👇
        </p>
        <p>
          👍 Jediná HR aplikace pro evidenci dat, automatizaci procesů
          i&nbsp;reportingu
        </p>
        <p>
          🤖 Eliminace rutinní práce a&nbsp;chyb s&nbsp;ní spojených ➝ výrazně
          šetříme čas i&nbsp;peníze
        </p>
        <p>
          👪 Přesné nastavení uživatelských práv&nbsp;– od lokální
          administrativy po manažery mateřské společnosti
        </p>
      </ReferenceImageText>

      <ReferenceImageText>
        <H2>V&nbsp;čem byl problém?</H2>
        <p>
          V&nbsp;Global IT Center, zajišťující IT služby a&nbsp;technickou
          podporu pro globálního dodavatele stavebních materiálů
          HeidelbergCement Group, používali na HR/Admin oddělení spoustu
          samostatných aplikací a&nbsp;excel dokumentů. Personalisté počítali
          dovolené pomocí složitých vzorců, pravidelně tvořili stále stejné
          reporty a&nbsp;manuálně evidovali všechny změny u&nbsp;zaměstnanců
          i&nbsp;pracovních pozic. Rutinní práce neměla konce, pravděpodobnost
          chyby byla nemalá.
        </p>
        <p>
          Pokud jste menší firma, s&nbsp;tabulkovými nástroji si v&nbsp;mnoha
          oblastech práce vystačíte. S&nbsp;růstem se ale začínají kolečka
          zadrhávat, úkoly kupit a&nbsp;náraz do zdi nevyhnutelně blížit.
        </p>
        <p>
          Co ale s&nbsp;tím, pokud víte, že přechod na existující HR nástroje
          znamená nemalé náklady a&nbsp;nespavost způsobenou ohýbáním vnitřních
          procesů?
        </p>
        <p>
          Navrhli jsme EMS aplikaci na míru, která veškerá data a&nbsp;postupy
          přenese na jedno místo a&nbsp;rutinní úkoly zautomatizuje:
        </p>
        <Content>
          <ul>
            <li>
              zaměstnanci na různých pozicích získají okamžitý přístup
              k&nbsp;aktuálním informacím, ze kterých snadno vytvoří potřebné
              reporty. Usnadní si tím každodenní práci,
            </li>
            <li>celá společnost tím výrazně ušetří čas i&nbsp;peníze,</li>
            <li>zcela odpadá riziko náhodných chyb.</li>
          </ul>
        </Content>
        <p>Jaká cesta k&nbsp;něčemu takovému vede?</p>
      </ReferenceImageText>

      <PaddingWrapper>
        <H2>Řešení: EMS aplikace na míru</H2>
        <PenguinsTimeline data={TIMELINE_GLOBALIT} />
      </PaddingWrapper>

      <PaddingWrapper noPaddingBottom>
        <ReferenceImageText>
          <Content noPadding>
            <ol>
              <li>
                <strong>Výzkum a&nbsp;analýza</strong>
                <p>
                  Aplikace na míru bude mít bez dobrého výzkumu jen jepičí
                  život. Sesbírali jsme proto důkladně (desk research, workshop,
                  kvalitativní rozhovory …) všechny informace o&nbsp;současné
                  pracovní náplni HR pracovníků a&nbsp;manažerů, nastavených
                  procesech či o&nbsp;představách, potřebách a&nbsp;očekáváních
                  spojených s&nbsp;novou aplikací. Šlo třeba o:
                </p>
                <ul>
                  <li>
                    automatizaci evidence změn u&nbsp;pozic a&nbsp;zaměstnanců,
                  </li>
                  <li>zaznamenávání úprav a&nbsp;uchovávání historie změn,</li>
                  <li>
                    zavedení možnosti podrobné, kontextuální filtrace dle
                    zadaných parametrů,
                  </li>
                  <li>
                    generování reportů pro jednotlivce, týmy i&nbsp;management,
                  </li>
                  <li>
                    správu uživatelských práv dle pozice či role ve firmě,
                  </li>
                </ul>
              </li>
              <li>
                <strong>Výběr technologie a&nbsp;prvotní návrh aplikace</strong>
                <p>
                  Aplikace vytváříme s&nbsp;důrazem na to, aby měl zákazník
                  kompletní přístup ke zdrojovému kódu bez závislosti na
                  konkrétním dodavateli (vendor lock-in). Zde jsme proto jako
                  základ zvolili otevřený systém Drupal&nbsp;9, silný právě
                  v&nbsp;práci s&nbsp;různými typy obsahu a&nbsp;dat
                  a&nbsp;jejich filtrací či v&nbsp;nastavení podrobného access
                  managementu.
                </p>
                <p>
                  Umíme sice zajistit i&nbsp;vlastní zabezpečený hosting, kvůli
                  interním pravidlům, citlivosti dat i&nbsp;GDPR klient ale
                  nakonec zvolil provoz aplikace na vlastní infrastruktuře.
                  Abychom mu ušetřili náklady spojené se správou odděleného
                  webového a&nbsp;databázového serveru, napojili jsme aplikaci
                  na Microsoft SQL server, který už používal.
                </p>
              </li>
              <li>
                <strong>Vybudování struktury dat</strong>
                <p>
                  V&nbsp;další fázi bylo nutné doplnit kostru aplikace daty,
                  které budeme u&nbsp;pozic a&nbsp;zaměstnanců evidovat.
                  Z&nbsp;povahy dat jsme vytvořili datovou strukturu, do níž
                  jsme pak importovali veškeré informace z&nbsp;doposud
                  používaných excel dokumentů a&nbsp;dalších nástrojů.
                </p>
                <p>
                  Nová data o&nbsp;zaměstnancích a&nbsp;kontraktorech se už plní
                  přímo do aplikace, která umí zároveň uchovávat změny. Pokud je
                  to tedy potřeba, personalisté se snadno dostanou ke starším
                  verzím a&nbsp;k vývoji úprav.
                </p>
              </li>
              <li>
                <strong>Práce s&nbsp;reporty a&nbsp;filtry</strong>
                <p>
                  Jedna z&nbsp;nejdůležitějších funkcí aplikace je právě snadná
                  a&nbsp;rychlá práce s&nbsp;filtry a&nbsp;reporty, jejich
                  automatické generování dle zadaných požadavků a&nbsp;do
                  formátu, uzpůsobeného k&nbsp;rychlému získání informací
                  a&nbsp;k&nbsp;relevantnímu vyhodnocení.
                </p>
                <strong>
                  Personalisté či management tak na pár kliknutí a&nbsp;na
                  jednom místě získají přehledy a&nbsp;výpisy např.&nbsp;o:
                </strong>
                <ul>
                  <li>volných i&nbsp;obsazených pozicích,</li>
                  <li>docházce a&nbsp;dovolených,</li>
                  <li>
                    rozpočtech na rozvoj či nábor a&nbsp;jejich čerpání
                    v&nbsp;čase,
                  </li>
                  <li>
                    školeních, termínech končících BOZP či lékařských
                    prohlídkách,
                  </li>
                  <li>evidenci čipových karet,</li>
                </ul>
                <p>
                  Detail zaměstnance pak umožňuje zobrazit jeho průchod firmou –
                  kdy nastoupil, kam kariérně vyrostl, kdo jej vedl,
                  v&nbsp;jakých týmech pracoval a&nbsp;s&nbsp;jakým úspěchem
                  apod.
                </p>
              </li>
              <Container className="mx-auto mb-5 text-center">
                <img
                  src={MobilniZobrazeni}
                  alt=""
                  className="img-fluid"
                  loading="lazy"
                  width={267}
                  height={512}
                />
                <p className="text-center pt-3">
                  <em>Ukázka mobilní verze aplikace.</em>
                </p>
              </Container>
              <p>
                Pro rychlý přístup k&nbsp;nejdůležitějším/nejčastěji používaným
                přehledům jsme uzpůsobili také dashboard, přístupný rovnou
                z&nbsp;domovské stránky webové aplikace. Přehledy jdou
                samozřejmě také exportovat.
              </p>
              <li>
                <strong>Automatizace na míru</strong>
                <p>
                  Po reportech přišly na řadu automatizace. Vycházeli jsme zde
                  ze scénářů reálného běhu procesů v&nbsp;Global IT Center,
                  získaných ve fázi výzkumu. Můžeme vypíchnout třeba:
                </p>
                <ul>
                  <li>
                    dojde-li k ukončení spolupráce, aplikace může okamžitě
                    a&nbsp;zcela automaticky vytvořit novou volnou pracovní
                    pozici,
                  </li>
                  <li>
                    změní-li se typ spolupráce z&nbsp;kontraktora na kmenového
                    zaměstnance (či naopak), systém automaticky vyřeší
                    návaznosti,
                  </li>
                  <li>
                    počty volných a&nbsp;obsazených pozic je možné sledovat
                    nejen k aktuálnímu dni, ale také ke kterémukoli dni
                    v&nbsp;minulosti či dokonce v&nbsp;budoucnosti (pokud někdo
                    již tyto plány zadal do systému),
                  </li>
                </ul>
              </li>
              <PaddingWrapper>
                <Medailon
                  noMargin
                  fullWidth
                  noCover
                  noPenguin
                  personName="Jan Nobilis"
                  position="vedoucí vývoje a jednatel"
                  img={Sobol}
                >
                  <p>
                    Tvorba aplikace představovala zajímavou výzvu od začátku do
                    konce. Ať už šlo o&nbsp;zmapování procesů, návrh struktury
                    aplikace, nebo naprogramování komplexní automatizace.
                    Vypíchnul bych hlavně funkcionalitu výstupů navázaných na
                    zadaný čas&nbsp;– výstupy jsou platné k&nbsp;libovolně
                    zvolenému datu v minulosti, současnosti i&nbsp;budoucnosti.
                  </p>
                </Medailon>
              </PaddingWrapper>
              <li>
                <strong>Nastavení uživatelských práv</strong>
                <p>
                  Poslední větší výzvu představovalo nastavení logiky
                  uživatelských rolí a&nbsp;skupin tak, aby korespondovaly
                  s&nbsp;právy a&nbsp;povinnostmi jednotlivých zaměstnanců.
                  Někteří tak disponují právy pro vkládání a&nbsp;editaci dat,
                  jiní si tyto informace ani nemohou zobrazit. Uzpůsobeny jsou
                  tomu i&nbsp;formuláře, obsahující jen ta pole, relevantní pro
                  daného člověka.
                </p>
                <p>
                  Každý tak pracuje jen s&nbsp;tím, co opravdu potřebuje.
                  Veškeré změny jsou opět ukládány v&nbsp;systému. Lze si tedy
                  jednoduše zobrazit, co, kdo, kdy a&nbsp;jakým způsobem
                  upravil.
                </p>
              </li>
            </ol>
          </Content>
        </ReferenceImageText>
      </PaddingWrapper>
      <PaddingWrapper>
        <ReferenceImageText>
          <H2>Další vývoj</H2>
          <p>
            Výsledná EMS aplikace zásadně usnadňuje a&nbsp;zrychluje práci
            prakticky všem oddělením&nbsp;– ať už jde o&nbsp;back-office, HR, či
            management mateřské společnosti. Šetří díky automatizaci spousty
            hodin práce i&nbsp;nervů, a&nbsp;to bez nutnosti upravovat dříve
            nastavené a&nbsp;zaběhlé procesy.
          </p>
          <p>
            My samozřejmě nepolevujeme a&nbsp;aplikaci rozšiřujeme o&nbsp;další
            funkce, dál ji také spravujeme a&nbsp;ručíme za její bezpečné
            fungování.
          </p>
          <p>
            Pálí vás denně obdobné problémy a rádi byste rutinní práci
            zautomatizovali? Dejte vědět a&nbsp;najdeme vhodné řešení. Pomoci
            umíme také s&nbsp;náborem či{" "}
            <Link to="/hr-marketing">HR marketingem</Link>.
          </p>
        </ReferenceImageText>
      </PaddingWrapper>

      <ContactForm contact="sobol" />
    </Layout>
  )
}

export default GlobalIT
